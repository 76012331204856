import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/banner/arrow_down.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/banner/banner.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/banner/healthy_lifestyle_1.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/order/order.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/footer/footer.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/order/underline.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/bloodsugar.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/chocolate.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/gastric.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/insulin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/overview.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/faq/faq.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/experts/experts.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/product-info/semagulutide-weight-loss.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/steps/consultation.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/steps/shipping.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/steps/steps.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/_sections/steps/survey.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/button/get-started.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/StrideSlim.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/toggle/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
